<template>
    <div :class="[
            {'o365-body-cell': !col.disableDefaultClass, 'o365-body-cell-editable': col.editable},
            selectionClass,
            col.cellClass,
            userClass,
        ]"
        :style="[
            {'width': col.width + 'px'},
            {'left': col.left + 'px'},
            userStyle
        ]"
        :o365-field="col.colId"
        :data-o365-colindex="col.order"
        :title="title">
        <slot>
            <template v-if="col.editable">
                <component v-if="editor.isSlot" :is="editor.node"
                    :modelValue="row" :row="row" 
                    :column="col" 
                    :class="col.editorClass" 
                    ref="editorRef"
                    v-bind="col.cellEditorParams">
                </component>
                <component v-else :is="editor.node"
                    v-model="row[col.field]"
                    :class="col.editorClass"
                    ref="editorRef"
                    v-bind="col.cellEditorParams, conditionalProps">
                </component>
            </template>
            <template v-else>
                <template v-if="col.slots.default">
                    <component :is="col.slots.default" :row="row" :column="col" :rowIndex="row.index"></component>
                </template>
                <template v-else-if="col.cellRenderer">
                    <component :is="col.cellRenderer" :row="row" :column="col"></component>
                </template>
                <template v-else>
                    {{row.item[col.field]}}
                </template>
            </template>
        </slot>
    </div>
</template>

<script lang="ts">
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';

export interface INewRecordCellProps {
    col: BaseColumn;
    row: any;
    rowIndex?: number;
    selectionClass?: string;
    isActiveEditCell?: boolean;
};
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<INewRecordCellProps>();


//--- Cell Styling ---

/** User provided class function from column definition */
const userClass = computed(() => {
    if (typeof props.col.classFn === 'function') {
        return props.col.classFn(props.row);
    } else {
        return null;
    }
});

/** User provided styles from column definition */
const userStyle = computed(() => {
    if (typeof props.col.cellStyle === 'function') {
        return props.col.cellStyle(props.row);
    } else {
        return props.col.cellStyle;
    }
});

/** Cell title from column definition */
const title = computed(() => {
    if (typeof props.col.cellTitle === 'function') {
        return props.col.cellTitle(props.row);
    } else {
        return props.col.cellTitle;
    }
});

//--- Editor logic ---

const editor = computed(() => {
    //deactivatePopupMode();
    if (props.col.slots.editor) {
        return { node: props.col.slots.editor, isSlot: true };
    } else {
        if (typeof props.col.cellEditor === 'string') {
            console.error(`${props.col.colId} - ${props.col.cellEditor} cell editors as strings are no longer supported, pass the component definition instead`)
            return {};
        } else {
            return { node: props.col.cellEditor, isSlot: false };
        }
    }
});

const editorProps = computed(() => {
    if (typeof editor.value === 'object' && editor.value?.node?.props) {
        if (Array.isArray(editor.value.node.props)) {
            return editor.value.node.props;
        } else {
            return Object.keys(editor.value.node.props);
        }
    } else {
        return [];
    }
});


const conditionalProps = computed(() => {
    const obj: any = {};
    if (editorProps.value) {    
        if (editorProps .value.includes('row')) {
            obj.row = props.row;
        }
        if (editorProps .value.includes('column')) {
            obj.column = props.col;
        }
    }
    return obj;
});

</script>